import { User2 } from "lucide-react";
import { Home } from "lucide-react";
import { Rocket } from "lucide-react";
import { Paperclip } from "lucide-react";

export const adminMenuBarItems = [
  {
    name: "License Dashboard",
    route: "/licenses-dashboard",
    icon: <Home />,
  },
  {
    name: "Review Licenses",
    route: "/licenses-approvals",
    icon: <Rocket />,
  },
  {
    name: "CE Dashboard",
    route: "/brokers-summary",
    icon: <Paperclip />,
  },
  {
    name: "Review CE",
    route: "/credit-approval",
    icon: <Paperclip />,
  },
  {
    name: "CE Guideline",
    route: "/guidelines",
    icon: <Paperclip />,
  },
  {
    name: "Emails",
    route: "/emails",
    icon: <Paperclip />,
  },
  {
    name: "Settings",
    route: "/settings",
    icon: <User2 />,
  },

  {
    name: "My Profile",
    route: "/profile",
    icon: <User2 />,
  },
];
export const managerSidebarItems = [
  {
    name: "License Dashboard",
    route: "/manager-license-dashboard",
    icon: <Home />,
  },
  {
    name: "Review Licenses",
    route: "/manager-review-licenses",
    icon: <Home />,
  },
  {
    name: "CE Dashboard",
    route: "/manager-ce-dashboard",
    icon: <Home />,
  },
  {
    name: "CE Guidelines",
    route: "/broker-guidelines",
    icon: <Home />,
  },
  {
    name: "My Profile",
    route: "/profile",
    icon: <Home />,
  },
];

export const systemAdminItems = [
  {
    name: "Dashboard",
    route: "/sys-admin-dashboard",
    icon: <Home />,
  },
  {
    name: "Organizations",
    route: "/sys-organizations",
    icon: <Home />,
  },
  {
    name: "Jurisdictions",
    route: "/sys-jurisdictions",
    icon: <Home />,
  },
  {
    name: "Guidelines",
    route: "/sys-guidelines",
    icon: <Home />,
  },
  {
    name: "Profile",
    route: "/profile",
    icon: <Home />,
  },
];

export const brokerItems = [
  {
    route: "/my-licenses",
    name: "My Licenses",
    icon: <Home />,
  },
  {
    route: "/dashboard",
    name: "My CE",
    icon: <Home />,
  },
  {
    route: "/broker-guidelines",
    name: "CE Guidelines",
    icon: <Home />,
  },
  {
    route: "/profile",
    name: "My Profile",
    icon: <Home />,
  },
];

export const roleBasedMenus = {
  admin: adminMenuBarItems,
  manager: managerSidebarItems,
  "sys-admin": systemAdminItems,
  user: brokerItems,
};
