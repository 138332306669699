import firebase from "../../firebase/firebaseConfig";
import { addSnapshotsListners } from "../app-settings/app-settings.action";

import { getUserBrokerData } from "../userBroker/userBroker.action";

export const createCreditOfBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "CREATE_CREDIT_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const addBrokerCreditIntoFirebase = firebase
        .functions()
        .httpsCallable("addBrokerCreditIntoFirebase");
      const result = await addBrokerCreditIntoFirebase(data);
      if (result.data.success) {
        dispatch({
          type: "CREATE_CREDIT_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: result.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log("err.message", err.message);
      dispatch({
        type: "CREATE_CREDIT_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
export const saveBrokerCreditToBank =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "CREATE_CREDIT_DATA_LOADING_TO_BANK",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const saveBrokerCreditToBank = firebase
        .functions()
        .httpsCallable("saveBrokerCreditToBank");
      const result = await saveBrokerCreditToBank(data);
      if (result.data.success) {
        dispatch({
          type: "CREATE_CREDIT_DATA_LOADING_TO_BANK",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: result.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "CREATE_CREDIT_DATA_LOADING_TO_BANK",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const deleteSavedCreditBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_DELETE_CREDIT_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const deleteBrokerSavedCredits = firebase
        .functions()
        .httpsCallable("deleteBrokerSavedCredits");
      const response = await deleteBrokerSavedCredits(data);
      if (response.data.success) {
        dispatch({
          type: "SET_DELETE_CREDIT_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: response.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DELETE_CREDIT_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const deleteCreditOfBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_DELETE_CREDIT_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const deletionBrokerCredit = firebase
        .functions()
        .httpsCallable("deletionBrokerCredit");
      const response = await deletionBrokerCredit(data);
      if (response.data.success) {
        dispatch({
          type: "SET_DELETE_CREDIT_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: response.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DELETE_CREDIT_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const fetchCreditOfBroker = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "ALL_CREDIT_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    const firestore = getFirestore();
    const email = getState().user.currentUser.email;
    const homeProvince = getState().user.currentUser.homeProvince;
    const userLicense = getState().user.currentUser.provinceLicense;
    const orgId = getState().user.currentUser.orgId;

    const brokerResultCreditRef = firestore
      .collection("organizations")
      .doc(orgId)
      .collection("credits")
      .where("email", "==", email)
      .where("orgId", "==", orgId)
      .where("province", "==", homeProvince)
      .where("license", "==", userLicense);

    const userCreditRef = firestore
      .collectionGroup("submission")
      .where("email", "==", email)
      .where("orgId", "==", orgId)
      .where("type", "!=", "draft");

    try {
      let brokerResultSnapshot = brokerResultCreditRef.onSnapshot(
        (response) => {
          let newCreditData = [];
          if (response.docs.length) {
            for (const doc of response.docs) {
              const data = doc.data();
              const finalDoc = {
                id: doc.id,
                ...data,
              };
              if (doc.ref.parent.path.includes("organizations")) {
                newCreditData.push(finalDoc);
              }
            }
          }

          dispatch({
            type: "SUBMISSION_CREDIT_DATA",
            payload: newCreditData,
          });
          dispatch(getUserBrokerData());
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "broker-result-snapshot",
          snapshot: brokerResultSnapshot,
        })
      );

      let brokerCreditDataSnapshot = userCreditRef.onSnapshot((response) => {
        let newCreditData = [];
        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();
            const finalDoc = {
              id: doc.id,
              ...data,
            };
            newCreditData.push(finalDoc);
          }
        }

        dispatch({
          type: "CREDIT_DATA",
          payload: newCreditData,
        });
        dispatch(getUserBrokerData());
      });
      dispatch(
        addSnapshotsListners({
          type: "broker-credit-data-snapshot",
          snapshot: brokerCreditDataSnapshot,
        })
      );
      dispatch({
        type: "ALL_CREDIT_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ALL_CREDIT_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchSavedCreditOfBrokers = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SAVED_CREDIT_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    const firestore = getFirestore();
    const email = getState().user.currentUser.email;
    const homeProvince = getState().user.currentUser.homeProvince;
    const userLicense = getState().user.currentUser.provinceLicense;

    const brokerDocQuery = firestore
      .collection("users")
      .where("email", "==", email)
      .where("homeProvince", "==", homeProvince)
      .where("provinceLicense", "==", userLicense);

    try {
      const broker = await brokerDocQuery.get();
      if (!broker.empty) {
        let brokerId = broker.docs[0].id;
        let savedCreditDataSnapshot = firestore
          .collection("users")
          .doc(brokerId)
          .collection("savedCredits")
          .onSnapshot((response) => {
            let newCreditData = [];
            if (response.docs.length) {
              for (const doc of response.docs) {
                const data = doc.data();
                const finalDoc = {
                  id: doc.id,
                  ...data,
                };
                newCreditData.push(finalDoc);
              }
            }

            dispatch({
              type: "SET_SAVED_CREDIT_DATA_TO_BANK",
              payload: newCreditData,
            });
            dispatch(getUserBrokerData());
          });
        dispatch(
          addSnapshotsListners({
            type: "saved-broker-credit-data-snapshot",
            snapshot: savedCreditDataSnapshot,
          })
        );

        dispatch({
          type: "SAVED_CREDIT_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        console.log("no Broker Exist with this email");
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SAVED_CREDIT_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchCreditApprovalDocs = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_CREDIT_APPROVAL_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;

    try {
      let creditApprovalDocsSnapshot = firestore
        .collectionGroup("submission")
        .where("status", "==", "incomplete")
        .where("orgId", "==", orgId)
        .onSnapshot(async (credits) => {
          let CreditApprovalData = [];
          for (let i in credits.docs) {
            const item = credits.docs[i];
            const brokerCreditStatuss = {
              submissionId: item.id,
              creditId: item.ref.parent.parent.id,
              ...item.data(),
            };
            if (item.ref.parent.path.includes("organizations")) {
              CreditApprovalData.push(brokerCreditStatuss);
            }
          }

          dispatch({
            type: "SET_CREDIT_APPROVAL_DATA",
            payload: CreditApprovalData,
          });
        });

      dispatch(
        addSnapshotsListners({
          type: "credit-approval-docs-snapshot",
          snapshot: creditApprovalDocsSnapshot,
        })
      );
      dispatch({
        type: "GET_CREDIT_APPROVAL_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_CREDIT_APPROVAL_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchApprovedAndRejectedCredits = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_CREDIT_APPROVED_REJECTED_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;

    try {
      let creditApprovalDocsSnapshot = firestore
        .collectionGroup("submission")
        .where("status", "in", ["rejected", "approved"])
        .where("orgId", "==", orgId)
        .onSnapshot(async (credits) => {
          let creditApprovedRejectedData = [];
          for (let i in credits.docs) {
            const item = credits.docs[i];
            const brokerCreditStatuss = {
              submissionId: item.id,
              creditId: item.ref.parent.parent.id,
              ...item.data(),
            };
            if (item.ref.parent.path.includes("organizations")) {
              creditApprovedRejectedData.push(brokerCreditStatuss);
            }
          }

          dispatch({
            type: "SET_CREDIT_APPROVED_REJECTED_DATA",
            payload: creditApprovedRejectedData,
          });
        });

      dispatch(
        addSnapshotsListners({
          type: "credit-approved-rejected-docs-snapshot",
          snapshot: creditApprovalDocsSnapshot,
        })
      );
      dispatch({
        type: "GET_CREDIT_APPROVED_REJECTED_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_CREDIT_APPROVED_REJECTED_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchApprovedRejectedCreditsWithBrokerEmail = (email) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_CREDIT_APPROVED_REJECTED_DATA_ON_BEHALF_BROKER_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;
    try {
      let approvedRejectedCreditOnBehalfBroker = firestore
        .collectionGroup("submission")
        .where("status", "==", "approved")
        .where("orgId", "==", orgId)
        .where("email", "==", email)
        .onSnapshot(async (credits) => {
          let creditApprovedRejectedDataOnBehalfBroker = [];
          for (let i in credits.docs) {
            const item = credits.docs[i];
            const brokerCreditStatuss = {
              submissionId: item.id,
              creditId: item.ref.parent.parent.id,
              ...item.data(),
            };
            if (item.ref.parent.path.includes("organizations")) {
              creditApprovedRejectedDataOnBehalfBroker.push(
                brokerCreditStatuss
              );
            }
          }

          dispatch({
            type: "SET_CREDIT_APPROVED_REJECTED_ON_BEHALF_BROKER_DATA",
            payload: creditApprovedRejectedDataOnBehalfBroker,
          });
        });

      dispatch(
        addSnapshotsListners({
          type: "credit-approved--on-behalf-broker-docs-snapshot",
          snapshot: approvedRejectedCreditOnBehalfBroker,
        })
      );
      dispatch({
        type: "GET_CREDIT_APPROVED_REJECTED_DATA_ON_BEHALF_BROKER_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const saveCreditOfBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SAVE_CREDIT_OF_BROKER_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const saveBrokerCreditIntoFirebase = firebase
        .functions()
        .httpsCallable("saveBrokerCreditIntoFirebase");
      const result = await saveBrokerCreditIntoFirebase(data);
      if (result.data.success) {
        dispatch({
          type: "SAVE_CREDIT_OF_BROKER_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: result.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SAVE_CREDIT_OF_BROKER_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
export const resetCreditLoading = () => async (dispatch) => {
  dispatch({
    type: "CREATE_CREDIT_DATA_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};
export const resetSaveCreditLoading = () => async (dispatch) => {
  dispatch({
    type: "CREATE_CREDIT_DATA_LOADING_TO_BANK",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetDeleteCreditLoading = () => ({
  type: "SET_DELETE_CREDIT_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetCreditLastDoc = () => ({
  type: "CREDIT_LIST_LAST_DOC",
  payload: "",
});

export const resetCreditDataArray = () => ({
  type: "CREDIT_DATA",
  payload: [],
});

export const resetCreditDataLoading = () => ({
  type: "ALL_CREDIT_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});
