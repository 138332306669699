import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDepartments } from "../redux/departments/department.action";

import { fetchGuidelines } from "../redux/Guidelines/guidelines.action";
import { getRoleTypeFromeFirebase } from "../redux/roleType/roleType.action";
import {
  fetchInvitedBrokerDataForManagerRole,
  fetchInvitedBrokersData,
} from "../redux/invitedBroker/invitedBroker.action";
import {
  fetchBrokersData,
  fetchBrokersDataForManagerRole,
  fetchFormerBrokers,
  getPercentageOfBRokers,
} from "../redux/broker/broker.action";
import {
  getCurrentUserJurisdiction,
  getJurisdictionType,
} from "../redux/jurisdictionType/jurisdictionType.action";
import {
  fetchApprovedAndRejectedCredits,
  fetchCreditApprovalDocs,
  fetchCreditOfBroker,
} from "../redux/credits/credit.action";

import HelmetComponent from "../components/helment-component/helmet-component";
import ReactSuspensComponent from "../components/react-suspense-component/react-suspense-component";
import {
  fetchAllJurisdictions,
  fetchAllOrganizations,
} from "../redux/sytemAdmin/systemAdmin.action";
import { getOrganiztionLabels } from "../redux/app-settings/app-settings.action";
import { getAllBrokerExpireLicensesForManager } from "../redux/licenses/licenses.action";
import Swal from "sweetalert2";
import "./layout.styles.scss";
import RiskwellSidebar from "../components/riskwell-sidebar/sidebar";
import RiskwellLoader from "../components/riskwell-loader/riskwell-loader";

const Layout = () => {
  const dispatch = useDispatch();
  const {
    authLoaded,
    role,
    authenticated,
    redirectPass,
    resetPassword,
    mergeAccount,
    manager,
  } = useSelector((state) => state.user.currentUser);
  const { changeBrokerRoleLoading } = useSelector((state) => state.broker);
  useEffect(() => {
    if (authenticated && role === "admin") {
      dispatch(getOrganiztionLabels());
      dispatch(getPercentageOfBRokers());
      dispatch(fetchBrokersData());
      dispatch(fetchInvitedBrokersData());
      dispatch(getDepartments());
      dispatch(getJurisdictionType());
      dispatch(getRoleTypeFromeFirebase());
      dispatch(fetchCreditApprovalDocs());
      dispatch(fetchGuidelines());
      dispatch(fetchFormerBrokers());
      dispatch(fetchApprovedAndRejectedCredits());
    } else if (authenticated && role === "user") {
      dispatch(getCurrentUserJurisdiction());
      dispatch(getJurisdictionType());
      dispatch(fetchCreditOfBroker());
      dispatch(fetchGuidelines());
    } else if (authenticated && role === "sys-admin") {
      dispatch(fetchAllJurisdictions());
      dispatch(fetchAllOrganizations());
    } else if (authenticated && role === "manager") {
      dispatch(getJurisdictionType());
      dispatch(fetchBrokersDataForManagerRole());
      dispatch(getAllBrokerExpireLicensesForManager());
      dispatch(fetchInvitedBrokerDataForManagerRole());
      dispatch(fetchGuidelines());
    }
  }, [dispatch, role, authenticated]);

  useEffect(() => {
    if (changeBrokerRoleLoading.loading) {
      Swal.fire({
        title: "Please Wait:",
        text: `Changing Role`,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [changeBrokerRoleLoading.loading, dispatch]);

  if (!authLoaded) {
    return <RiskwellLoader />;
  }

  const checkRoleAndAuth = (role, authenticated) => {
    if (role === "admin" && authenticated) {
      return "admin";
    } else if (role === "sys-admin" && authenticated) {
      return "sys-admin";
    } else if (role === "manager" && authenticated) {
      return "manager";
    } else if (role === "user" && authenticated) {
      return "user";
    }
  };

  return (
    <div
      className={`layout-component ${checkRoleAndAuth(role, authenticated)}`}
    >
      <HelmetComponent role={role} />
      {authenticated && <RiskwellSidebar />}
      <ReactSuspensComponent
        redirectPass={redirectPass}
        resetPassword={resetPassword}
        role={role}
        authenticated={authenticated}
        mergeAccount={mergeAccount}
        manager={manager}
      />
    </div>
  );
};

export default Layout;
